export const GENERIC_PARAMETERS = {
  AUTOMATIC_LINKAGE_SEQUENCING: 'tradesman_sequencing',
  DELAYED: 'delayed',
  PAUSE_SERVICE_ORDER: 'pause_service_order',
  REPORTS: 'reports',
  STEP_STATUS_STRUCTURE: 'step_status_structure',
  ALLOW_HELPREQUEST_PAUSE_SERVICE: 'allow_helprequest_pause_service',
  DISTRIBUTION: 'assisted_distribution',
  NEW_SERVICE_ORDER_FORMS: 'new_service_order_form',
  WHATSAPP_BUTTON: 'whatsapp_button',
  EMERGENCY_MANAGEMENT: 'emergency_management',
  RS_MANTEINANCE_SCREEN: 'rs_manteinance_screen',
  SHOW_ALERT_MESSAGE: 'show_alert_message',
}

// Step status
export const STEP_STATUS = {
  ALLOCATE_PROVIDER: 'allocate_provider',
  WAITING_SERVICE_ORDER_ACCEPTANCE: 'waiting_service_order_acceptance',
  PROVIDER_REFUSED_SERVICE_ORDER: 'provider_refused_service_order',
  WAITING_PAYER_APPROVE_INNER_BUDGET: 'waiting_payer_approve_inner_budget',
  WAITING_BUDGET_APPROVAL: 'waiting_budget_approval',
  WAITING_BUDGET_SCHEDULE: 'waiting_budget_schedule',
  WAITING_BUDGET: 'waiting_budget',
  REVIEW_BUDGET: 'review_budget',
  SEND_QUOTE_TO_PAYER: 'send_quote_to_payer',
  WAITING_FOR_PAYER_APPROVAL: 'waiting_for_payer_approval',
  BUDGET_APPROVED_PAYER: 'budget_approved_payer',
  BUDGET_REFUSED_PAYER: 'budget_refused_payer',
  PAYER_APPROVED_INNER_BUDGET: 'payer_approved_inner_budget',
  PAYER_REFUSED_INNER_BUDGET: 'payer_refused_inner_budget',
  SEND_TO_EXECUTION: 'send_to_execution',
  WAITING_SERVICE_SCHEDULE: 'waiting_service_schedule',
  WAITING_FOR_SERVICE_EXECUTION: 'waiting_for_service_execution',
  WAITING_COUNTER_PROPOSAL_APPROVAL: 'waiting_counter_proposal_approval',
  ALLOCATE_TRADESMAN_TO_PRE_APPROVED_PROPOSAL: 'allocate_tradesman_to_pre_approved_proposal',
  WAITING_PRE_APPROVED_PROPOSAL_ACCEPTANCE: 'waiting_pre_approved_proposal_acceptance',
  SERVICE_FINISHED: 'service_finished',
  WAITING_FINALIZATION_APPROVAL: 'waiting_finalization_approval',
  SERVICE_FINALIZATION_REPROVED: 'service_finalization_reproved',
  SERVICE_FINALIZATION_REPROVED_REQUESTER: 'service_finalization_reproved_requester',
  SERVICE_ORDER_FINISHED: 'service_order_finished',
  SERVICE_ORDER_CANCELED: 'service_order_canceled',
  BUDGET_REFUSED_TRADESMAN: 'budget_refused_tradesman',
  PENDING_OWNER: 'pending_owner',
  PENDING_THIRD_PARTY: 'pending_third_party',
  REQUEST_FOR_REIMBURSEMENT: 'request_for_reimbursement',
  COMPLETED_EXTRA_REFERA: 'completed_extra_refera',
  INSPECTION_CONTESTATION: 'inspection_contestation',
  AUTH_IMPROVEMENTS_DISCARDS: 'auth_improvements_discards',
  SEND_INNER_BUDGET_TO_PAYER: 'send_inner_budget_to_payer',
  PROBLEMS_FINISHING_SERVICE: 'problems_finishing_service',
  NEW_INTERMEDIARY: 'new_intermediary',
  INNER_BUDGET_REFUSED: 'inner_budget_refused',
  WAITING_PAYMENT_INFO: 'waiting_payment_info',
  WAITING_POOL_ACCEPTANCE: 'waiting_pool_acceptance',
  WAITING_PRE_APPROVED_PROPOSAL_POOL: 'waiting_pre_approved_proposal_pool',
}

export const INTERMEDIARY_STATUS = [
  STEP_STATUS.NEW_INTERMEDIARY,
  STEP_STATUS.PENDING_OWNER,
  STEP_STATUS.PENDING_THIRD_PARTY,
  STEP_STATUS.REQUEST_FOR_REIMBURSEMENT,
  STEP_STATUS.INSPECTION_CONTESTATION,
  STEP_STATUS.AUTH_IMPROVEMENTS_DISCARDS,
  STEP_STATUS.COMPLETED_EXTRA_REFERA,
  STEP_STATUS.INTERMEDIARY_REVIEW_BUDGET,
]

// Button actions
export const BUTTONS_ACTION = {
  APPROVE_BUDGET: 'approve_budget',
  APPROVE_INNER_BUDGET: 'approve_inner_budget',
  CANCEL_APPROVAL: 'cancel_approval',
  CANCEL_REVIEW: 'cancel_review',
  COMPLETE_CANCELED_SERVICE_ORDER: 'complete_canceled_service_order',
  EDIT_BUDGET_BY_PROVIDER: 'edit_budget_by_provider',
  EDIT_PAYER_DATA: 'edit_payer_data',
  REPROVE_BUDGET: 'reprove_budget',
  REPROVE_INNER_BUDGET: 'reprove_inner_budget',
  RETURN_BUDGET: 'return_budget',
  REVIEW_BUDGET: 'review_budget',
  SELECT_TRADESMAN: 'select_tradesman',
  ALLOCATE_TRADESMAN_INNER_BUDGET: 'allocate_tradesman_inner_budget',
  TEMPORARY_SAVE: 'temporary_save',
  TRADESMAN_ACCEPT_SERVICE_ORDER: 'tradesman_accept_service_order',
  TRADESMAN_REFUSE_SERVICE_ORDER: 'tradesman_refuse_service_order',
  PROVIDER_SEND_BUDGET: 'provider_send_budget',
  TRADESMAN_REFUSE_INNER_BUDGET: 'tradesman_refuse_inner_budget',
  SERVICE_EXECUTION: 'service_execution',
  CLAIM_WARRANTY: 'claim_warranty',
  WAITING_BUDGET_SCHEDULE: 'waiting_budget_schedule',
  SCHEDULE_SERVICE_EXECUTION: 'schedule_service_execution',
  FINISH_SERVICE: 'finish_service',
  CANCEL_SERVICE_FINISH: 'cancel_service_finish',
  FINISH_SERVICE_ORDER: 'finish_service_order',
  CANCEL_EXECUTION_SCHEDULE: 'cancel_execution_schedule',
  SENT_RATING_LINK: 'sent_rating_link',
  RATE_SERVICE: 'rate_service',
  SEND_INVOICE: 'send_invoice',
  IMPORT_BUDGET_TEMPLATE: 'import_budget_template',
  EDIT_PAYER_RESPONSIBLE: 'edit_payer_responsible',
  INTERMEDIARY_REVIEW_BUDGET: 'intermediary_review_budget',
  INTERMEDIARY_REFUSE_BUDGET: 'intermediary_refuse_budget',
  EDIT_COVERAGE: 'edit_coverage',
  VIEW_COVERAGE: 'view_coverage',
  IS_REFERA_SERVICE: 'is_refera_service',
  CANCEL_SERVICE_ORDER: 'cancel_service_order',
  ADJUST_FLUX: 'adjust_flux',
  SAAS_ADD_BUDGET: 'saas_add_budget',
  EDIT_BUDGET: 'edit_budget',
  ACCEPT_BY_TRADESMAN: 'accept_by_tradesman',
  ACCEPT_INNER_BUDGET_BY_TRADESMAN: 'accept_inner_budget_by_tradesman',
  SEND_BUDGET: 'send_budget',
}

export const STATUS_RATING = {
  LINK_SENT: 'link_sent',
  SERVICE_REPROVED: 'service_repproved',
  SERVICE_APPROVED: 'service_approved',
  RATED_SERVICE: 'rated_service',
}

// Actions Deadline
export const DEADLINE = {
  [STEP_STATUS.WAITING_SERVICE_ORDER_ACCEPTANCE]: {
    label: 'Prazo para o aceite',
    info: {
      title: 'Limite de tempo para o aceite do pedido',
      text: 'Todo pedido tem um tempo limite para ser aceito por você. O temporizador mostra quanto tempo você ainda tem, e ele só conta durante o horário comercial.',
      button: 'Ok, entendi',
    },
  },
  [STEP_STATUS.WAITING_BUDGET_SCHEDULE]: {
    label: 'Prazo agendar visita',
    info: {
      title: 'Prazo agendar visita',
      text: `Prazo agendar visita é o dia/hora limite que você tem para entrar em contato com o cliente e marcar a visita para você realizar o orçamento.
      Ahh! Caso você já tenha agendado, não esqueça de colocar essa informação no sistema, selecionando o chamado e em seguida pressionando o botão Agendar visita.`,
      button: 'Ok, entendi',
    },
  },
  [STEP_STATUS.WAITING_BUDGET]: {
    label: 'Prazo para enviar',
    info: {
      title: 'Prazo para enviar',
      text: 'Prazo para enviar é o dia/hora limite que você tem para enviar o orçamento do serviço.',
      button: 'Ok, entendi',
    },
  },
  [STEP_STATUS.WAITING_PRE_APPROVED_PROPOSAL_ACCEPTANCE]: {
    label: 'Prazo para aceitar',
    info: {
      title: 'Prazo para aceitar',
      text: `Prazo para aceitar é o dia/hora limite que você tem para aceitar ou recusar um orçamento que já está pré-aprovado.
      Não perca essa chance! Esse é um orçamento que preparamos para você e que o cliente já aceitou.
      Só falta você dizer que aceita também, e depois já pode agendar a execução com o cliente ;)`,
      button: 'Ok, entendi',
    },
  },
  [STEP_STATUS.WAITING_SERVICE_SCHEDULE]: {
    label: 'Prazo agendar exec.',
    info: {
      title: 'Prazo agendar execução',
      text: `Prazo agendar visita é o dia/hora limite que você tem para entrar em contato com o cliente e marcar a execução dos serviços.
      Ahh! Caso você já tenha agendado a execução, não esqueça de colocar essa informação no sistema, selecionando o chamado e em seguida pressionando o botão Agendar execução.`,
      button: 'Ok, entendi',
    },
  },
  [STEP_STATUS.SERVICE_FINISHED]: {
    label: 'Prazo para finalizar',
    info: {
      title: 'Prazo para finalizar',
      text: 'Prazo para finalizar é o dia/hora limite que você tem para finalizar o chamado, enviando as fotos dos serviços executados e a nota fiscal.',
      button: 'Ok, entendi',
    },
  },
  [STEP_STATUS.SERVICE_FINALIZATION_REPROVED]: {
    label: 'Prazo para resolver',
    info: {
      title: 'Prazo para resolver',
      text: `Prazo para resolver é o dia/hora limite que você tem para resolver alguma questão pendente no chamado para que ele possa ser aprovado.
      Entre no chamado, leia atentamente o ""Motivo da reprovação"" e execute as ações necessárias para resolver a situação. Depois que resolver tudo, finalize o chamado novamente.

      Caso você tenha solicitado uma antecipação no pagamento desse chamado, ela só será aprovada depois que a situação do chamado seja resolvida. (Respeitando os prazos da modalidade de antecipação).`,
      button: 'Ok, entendi',
    },
  },
  [STEP_STATUS.WAITING_FOR_SERVICE_EXECUTION]: {
    label: 'Prazo finalizar serviço',
    info: {
      title: 'Prazo finalizar serviço',
      text: `Prazo para finalizar serviço é o dia/hora limite que você tem para indicar que finalizou o serviço.

      Sabemos que imprevistos acontecem. Então, caso tenha qualquer problema durante a execução e veja que vai demandar mais tempo, não esqueça de avisar a Refera.`,
      button: 'Ok, entendi',
    },
  },
  [STEP_STATUS.SERVICE_FINALIZATION_REPROVED_REQUESTER]: {
    label: 'Prazo agendar exec.',
    info: {
      title: 'Prazo agendar execução',
      text: `Prazo agendar visita é o dia/hora limite que você tem para entrar em contato com o cliente e marcar a execução dos serviços.
      Ahh! Caso você já tenha agendado a execução, não esqueça de colocar essa informação no sistema, selecionando o chamado e em seguida pressionando o botão Agendar execução.`,
      button: 'Ok, entendi',
    },
  },
}

// Event
export const EVENT_TYPES = {
  ALLOCATE_PROVIDER: 'allocateProvider',
  WAIT_BUDGET: 'waitBudget',
  REVIEW_BUDGET: 'reviewBudget',
  SEND_BUDGET_PAYER: 'sendBudgetPayer',
  WAIT_APPROVE_PAYER: 'waitApprovePayer',
  WAIT_APPROVE_COUNTER_PROPOSAL: 'waitApproveCounterProposal',
  ALLOCATE_PROVIDER_PRE_APPROVED: 'allocateProviderPreApproved',
  SEND_EXECUTION: 'sendExecution',
  WAIT_EXECUTION_SERVICE: 'waitExecutionService',
  SERVICE_EXECUTION: 'serviceExecution',
  SERVICE_FINISHED_REPPROVED: 'serviceFinishedRepproved',
  WAIT_APPROVE_FINISH: 'waitApproveFinish',
  SERVICE_ORDER_FINISH: 'serviceOrderFinish',
  CANCELLED: 'cancelled',
}

export const EVENT_TYPES_LABEL = {
  [EVENT_TYPES.ALLOCATE_PROVIDER]: 'Alocar prestador',
  [EVENT_TYPES.WAIT_BUDGET]: 'Aguardando orçamento',
  [EVENT_TYPES.REVIEW_BUDGET]: 'Revisar orçamento',
  [EVENT_TYPES.SEND_BUDGET_PAYER]: 'Enviar orçamento ao pagador',
  [EVENT_TYPES.WAIT_APPROVE_PAYER]: 'Aguardando aprovação do pagador',
  [EVENT_TYPES.WAIT_APPROVE_COUNTER_PROPOSAL]: 'Aguardando aprovação de contra-proposta',
  [EVENT_TYPES.ALLOCATE_PROVIDER_PRE_APPROVED]: 'Alocar prestador para proposta pré-aprovada',
  [EVENT_TYPES.SEND_EXECUTION]: 'Enviar para execução',
  [EVENT_TYPES.WAIT_EXECUTION_SERVICE]: 'Aguardando execução do serviço',
  [EVENT_TYPES.SERVICE_EXECUTION]: 'Serviço finalizado',
  [EVENT_TYPES.SERVICE_FINISHED_REPPROVED]: 'Finalização do serviço reprovada',
  [EVENT_TYPES.WAIT_APPROVE_FINISH]: 'Aguardando aprovação da finalização',
  [EVENT_TYPES.SERVICE_ORDER_FINISH]: 'Chamado finalizado',
  [EVENT_TYPES.CANCELLED]: 'Cancelado',
}

export const EVENT_TYPES_OPTIONS = Object.values(EVENT_TYPES).map(value => ({
  value,
  label: EVENT_TYPES_LABEL[value],
}))

export const ROLE_GROUP_CHOICES = {
  LESSEE_GROUP: 'lessee_group', // "Solicitante,
  PAYER_GROUP: 'payer_group', // "Pagador,
  INTERMEDIARY_GROUP: 'intermediary_group', // "Intermediária,
  TRADESMAN_GROUP: 'tradesman_group', // "Prestador,
  REFERA_GROUP: 'refera_group', // "Refera,
  OWNER_GROUP: 'owner_group', // "Proprietário,
}
