export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const PHONE_NUMBER_REGEX =
  /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/

export const PHONE_REGEX = /^\+55\s\(\d{2}\)\s9\s\d{4}-\d{4}$/

export const CPF_CNPJ_REGEX =
  /^(\d{3}\.\d{3}\.\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}|\d{11})?$/

export const WIDGETS_ROUTE = {
  WIDGETS: '/widgets',
}

export const ROUTES = {
  MANAGE_ORDERS: '/',
  NEW_MANAGE_ORDERS: '/chamados-novo',
  SERVICE_ORDER: '/chamado',
  SETUP_AGENCY_FORM: '/solicitar-manutencao/:agencySlug',
  NEW_SERVICE: '/solicitar-servico',
  NEW_SERVICE_LOGGED: '/abrir-chamado',
  SETUP_AGENCY_FORM_NO_SLUG: '/solicitar-manutencao',
  GENERATE_CONTRACT: '/gerar-contrato',
  LOGIN: '/login',
  PASSWORD_RECOVER: '/password-recover',
  AGENCY: '/imobiliaria',
  CREATE_AGENCY: '/criar-imobiliaria',
  PROVIDER: '/prestador',
  CREATE_PROVIDER: '/criar-prestador',
  MANAGE_PROVIDERS: '/gestao-prestadores',
  MANAGE_AGENCIES: '/gestao-imobiliarias',
  RECOVER_PASSWORD: '/recuperar-senha/:uid/:token',
  BUDGET: '/orcamento',
  BUDGET_PROVIDER: '/orcamento-prestador',
  BUDGET_VIEW: '/editar-orcamento',
  COUNTER_PROPOSAL_PROVIDER: '/contra-proposta-prestador',
  HELP_REQUESTS: '/pedidos-ajuda',
  TRADESMAN: '/inicio',
  CONDOLIVRE_CONTRACT: '/contrato-condolivre',
  NEW_ORDERS: '/novos-chamados',
  DELAYED_ORDERS: '/chamados-atrasados',
  REPORTS: '/relatorios',
  INSTALLMENTS_RECEIPTS: '/recebimentos',
  NOT_FOUND: '/nao-encontrado',
  MENSAGERIA: '/mensageria',
  STEP_MESSAGE: '/mensageria',
  CLASSIFICATION_FORM: '/classificacao',
  CLASSIFICATION_LIST: '/classificacoes',
  VIP_CLIENTS_LIST: '/clientes-vip',
  DETAIL_SERVICE_ORDER: '/detalhes-do-chamado',
  SEND_RATING_LINK: '/customer-rating',
  LANDING: '/landing',
  LANDING_PAGE_B2C: '/lp-refera-b2c',
  LANDING_PAGE_B2C_AGENCY: '/lp-refera-b2c/:agencySlug',
  SERVICE_FINISHED: '/servico-finalizado',
  NF_FORM: '/nota-fiscal-form',
  FINANCE_MANAGER: '/pagamentos',
  LINK_EXPIRED: '/link-expirado',
  ACCESS_DENIED: '/acesso-negado',
  CATEGORY: '/categoria',
  PROBLEMS: '/problemas',
  SERVICE: '/servico',
  MAINTENANCE_SYSTEM: '/system-maintenance',
  BUDGET_TEMPLATE: '/budget-templates',
  COVERAGE_RULES: '/coverage-rules',
  REFERA_COVERAGE: '/refera-coverage',
  PROFILES: '/perfis',
  WIDGETS: '/widgets',
  REFERA_USERS: '/usuarios-refera',
  GROUP: '/grupos',
  MANAGER_PERMISSIONS: '/grupos/gerenciar-permissoes',
  GROUP_PERMISSIONS: '/grupos/permissoes',
  LEAD_PROVIDERS: '/importacao-de-prestadores',
  SERVICE_ORDERS: '/chamados',
  REFERA_DATA: '/dados-da-refera',
  MACRO_STEPS: '/macro-etapas',
}

export const FINANCE_ROUTES = {
  FINANCE: '/financeiro',
  PAYMENT_ADVANCE: '/financeiro/pedidos-antecipacao',
  RECEIPTS: '/financeiro/recebimentos-refera',
  RECEIPT_DETAILS: '/financeiro/recebimentos-refera/detalhes-recebimento',
  RECEIPTS_ASAAS: '/financeiro/recebimentos-refera/exportacao-asaas',
  MANAGE_INSTALLMENTS: '/financeiro/pagamento-chamados',
  BATCHES: '/financeiro/pagamento-chamados/lotes',
  BANK_RETURN_FILES: '/financeiro/pagamento-chamados/arquivos-retorno',
  CHARGE_FILES: '/financeiro/pagamento-chamados/arquivos-cobranca',
}

export const USER_MODULES = {
  MARKETPLACE: 'marketplace',
  FINANCE: 'finance',
}

export const SERVICE_ORDER_STATUS = {
  IN_PROGRESS: 'in_progress',
  PENDING: 'pending',
  NEW: 'new',
  FINISHED: 'finished',
  ALL: 'all',
}

export const ORDER_STATUS_LABELS = {
  [SERVICE_ORDER_STATUS.IN_PROGRESS]: 'Pedido',
  [SERVICE_ORDER_STATUS.NEW]: 'Orçamento',
  [SERVICE_ORDER_STATUS.FINISHED]: 'Ordem de serviço',
}

export const STAGE_STATUS = {
  ORDER: 'order',
  BUDGET: 'budget',
  EXECUTION: 'execution',
  FINISHED: 'finished',
  CANCELLED: 'cancelled',
}

export const STAGE_STATUS_LABELS = {
  [STAGE_STATUS.ORDER]: 'Pedido',
  [STAGE_STATUS.BUDGET]: 'Orçamento',
  [STAGE_STATUS.EXECUTION]: 'Ordem de serviço',
  [STAGE_STATUS.FINISHED]: 'Encerrado',
  [STAGE_STATUS.CANCELLED]: 'Cancelado',
}

export const REGISTER_STATUS = {
  COMPLETE: 'registration_completed',
  INCOMPLETE: 'registration_incomplete',
}

export const REGISTER_STATUS_LABEL = {
  [REGISTER_STATUS.COMPLETE]: 'Cadastro completo',
  [REGISTER_STATUS.INCOMPLETE]: 'Cadastro incompleto',
}

export const name = process.env.NODE_ENV === 'development' ? 'authTokenrefera' : 'authToken'

const REFRESH_TOKEN_COOKIE_NAME = '@Refera/refresh-token'

export const COOKIES_OPTIONS = {
  path: '/',
  authTokenName: name,
  refreshTokenName: REFRESH_TOKEN_COOKIE_NAME,
}

export const SERVICE_ORDER_VALUES = {
  READ: 'read',
  ID: 'id',
  EXTERNAL_ID: 'externalId',
  REAL_STATE: 'realState',
  PROPERTY_CODE: 'propertyCode',
  MORE_INFORMATION: 'moreInformation',
  SERVICES: 'services',
  PRIORITY: 'priority',
  CONTACT_NAME: 'contactName',
  ORDER_STATUS: 'orderStatus',
  STAGE_STATUS: 'stage',
  PROPERTY: 'property',
  CATEGORY: 'category',
  RESPONSIBLE: 'responsible',
  ARROW: 'arrow',
  VISIT_SCHEDULE: 'visitSchedule',
  STEP_STATUS: 'stepDesc',
  LOCATION: 'location',
  TOTAL_PRICE: 'totalPrice',
  AGENCY: 'agency',
  CLASSIFICATION: 'classification',
  FLOW: 'flow',
  IS_EMERGENCY: 'emergency',
  HAS_ANOTHER_BUDGET: 'hasAnotherBudget',
}
export const MESSAGING_VALUES = {
  ID: 'id',
  BUSINESS_FRONT: 'businessFront',
  DESTINATION: 'destination',
  STEP_STATUS: 'stepStatus',
  DEFINITE_ITEM: 'setItem',
  DEFAULT: 'default',
}

export const BANK_ACCOUNT_TYPE_OPTIONS = [
  { value: '1', label: '001 – Conta Corrente de Pessoa Física' },
  { value: '2', label: '002 – Conta Simples de Pessoa Física' },
  { value: '3', label: '003 – Conta Corrente de Pessoa Jurídica' },
  { value: '6', label: '006 – Entidades Públicas' },
  { value: '7', label: '007 – Depósitos Instituições Financeiras' },
  { value: '13', label: '013 – Poupança de Pessoa Física' },
  { value: '22', label: '022 – Poupança de Pessoa Jurídica' },
  { value: '28', label: '028 – Poupança de Crédito Imobiliário' },
  { value: '43', label: '043 – Depósitos Lotéricos' },
]

export const KEY_PIX_OPTIONS = [
  { value: 'random', label: 'Chave aleatória' },
  { value: 'cpf', label: 'CPF/CNPJ' },
  { value: 'email', label: 'E-mail' },
  { value: 'phone_number', label: 'Telefone' },
]

export const PROVIDER_VALUES = {
  NAME: 'name',
  RESPONSIBLE: 'personResponsibleName',
  PHONE: 'phone',
  CNPJ: 'cnpj',
  CITIES: 'cities',
  EMAIL: 'contactEmail',
  LAST_ASSIGNMENT_TIME: 'lastAssignmentTime',
  STATUS: 'status',
  CATEGORY: 'mainServices',
}

export const PROPERTY_VALUES = {
  ID: 'id',
  REAL_STATE: 'agency',
  ADDRESS: 'address',
  NUMBER: 'number',
  CEP: 'cep',
  NEIGHBORHOOD: 'neighborhood',
  COMPLEMENT: 'complement',
  CITY: 'city',
  CITY_LINK: 'cityLink',
  CITY_NAME: 'cityName',
  NEARBY_LANDMARK: 'nearbyLandmark',
  EXTRA_ADDRESS_INFO: 'complement',
  CODE: 'code',
}

export const PROPERTY_LABELS = {
  [PROPERTY_VALUES.ADDRESS]: 'Endereço',
  [PROPERTY_VALUES.CEP]: 'CEP',
  [PROPERTY_VALUES.EXTRA_ADDRESS_INFO]: 'Complemento',
  [PROPERTY_VALUES.CODE]: 'Código do imóvel',
  [PROPERTY_VALUES.NUMBER]: 'Número',
  [PROPERTY_VALUES.CITY]: 'Cidade',
  [PROPERTY_VALUES.NEIGHBORHOOD]: 'Bairro',
  [PROPERTY_VALUES.BUDGET_VISIT]: 'Visita para orçamento em',
}
export const BANK_ACCOUNT_FIELDS = {
  BANK: 'bank',
  BANK_ACCOUNT_CPF_CNPJ: 'bankAccountCpfCnpj',
  BANK_ACCOUNT_OWNER_NAME: 'bankAccountOwnerName',
  BANK_ACCOUNT_TYPE: 'bankAccountType',
  BANK_ACCOUNT_NUMBER: 'bankAccountNumber',
  BANK_AGENCY_NUMBER: 'bankAgencyNumber',
  PIX_KEY: 'pixKey',
  PIX_KEY_CHOICE: 'pixKeyChoice',
  PROFILE_PICTURE: 'profilePicture',
}

export const STAGE_TYPES = {
  ORDER: 'order',
  BUDGET: 'budget',
  EXECUTION: 'execution',
  FINISHED: 'finished',
  CANCELLED: 'cancelled',
  ALL_STAGES: 'allStages',
}

export const STAGE_TYPES_LABEL = {
  [STAGE_TYPES.ORDER]: 'Pedido',
  [STAGE_TYPES.BUDGET]: 'Orçamento',
  [STAGE_TYPES.EXECUTION]: 'Ordem de serviço',
  [STAGE_TYPES.FINISHED]: 'Encerrado',
  [STAGE_TYPES.CANCELLED]: 'Cancelados',
  [STAGE_TYPES.ALL_STAGES]: 'Todos os chamados',
}

export const STAGE_TYPES_COMPANY = {
  HOMOLOGATION: 'homologation',
  WAITING_LIST: 'waiting_list',
  FIRST_REQUEST: 'first_request',
  FIRST_BUDGET: 'first_budget',
  BUDGET_CORRECTION: 'budget_correction',
  FIRST_APPROVAL: 'first_approval',
  FIRST_EXECUTION: 'first_execution',
  ONBOARDING_FINISHED: 'onboarding_finished',
  RELEASED: 'released',
  BANK_DATA: 'bank_data',
  SUSPENDED: 'suspended',
  PAUSED: 'paused',
  RECOVERY: 'recovery',
  CLOSING_PROCESS: 'closing_process',
  REGISTRATION_CLOSED: 'registration_closed',
}

export const STAGE_TYPES_COMPANY_LABEL = {
  [STAGE_TYPES_COMPANY.HOMOLOGATION]: 'Homologação',
  [STAGE_TYPES_COMPANY.WAITING_LIST]: 'Lista de Espera',
  [STAGE_TYPES_COMPANY.FIRST_REQUEST]: 'Aguardando solicitação',
  [STAGE_TYPES_COMPANY.FIRST_BUDGET]: 'Aguardando orçamento',
  [STAGE_TYPES_COMPANY.BUDGET_CORRECTION]: 'Alinhamentos',
  [STAGE_TYPES_COMPANY.FIRST_APPROVAL]: 'Aguardando aprovação',
  [STAGE_TYPES_COMPANY.FIRST_EXECUTION]: 'Execução em andamento',
  [STAGE_TYPES_COMPANY.BANK_DATA]: 'Aguardando dados',
  [STAGE_TYPES_COMPANY.ONBOARDING_FINISHED]: 'Onboarding OK',
  [STAGE_TYPES_COMPANY.SUSPENDED]: 'Suspenso',
  [STAGE_TYPES_COMPANY.PAUSED]: 'Em Pausa',
  [STAGE_TYPES_COMPANY.RECOVERY]: 'Em recuperação',
  [STAGE_TYPES_COMPANY.CLOSING_PROCESS]: 'Em processo de encerramento',
  [STAGE_TYPES_COMPANY.REGISTRATION_CLOSED]: 'Cadastro encerrado',
  [STAGE_TYPES_COMPANY.RELEASED]: 'Liberado',
}

export const AGENCY_TYPES = {
  MY_AGENCIES: 'myAgencies',
}

export const AGENCY_TYPES_LABEL = {
  [AGENCY_TYPES.MY_AGENCIES]: '*Minhas intermediárias',
}

export const SERVICE_ORDER_STATE = {
  REQUEST: {
    id: 1,
    label: 'Pedido',
  },
  BUDGET: {
    id: 2,
    label: 'Orçamento',
  },
  SERVICE_ORDER: {
    id: 3,
    label: 'Ordem de Serviço',
  },
  FINISHED: {
    id: 4,
    label: 'Terminado',
  },
  CANCELED: {
    id: 5,
    label: 'Cancelado',
  },
}
export const ORDER_BY_VALUES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const STAGE_TYPES_OPTIONS = Object.values(STAGE_TYPES).map(value => ({
  value,
  label: STAGE_TYPES_LABEL[value],
}))

export const AGENCY_TYPES_OPTIONS = Object.values(AGENCY_TYPES).map(value => ({
  value,
  label: AGENCY_TYPES_LABEL[value],
}))

export const DEFAULT_ALL_OPTION = { value: '', label: '(Todos)' }

export const TAX_REGIMES = {
  WITHOUT_TAX: ['mei'],
}

export const ANTICIPATIONS_MORE_INFO_URL =
  'https://refera.movidesk.com/kb/article/434687/o-que-e-um-pedido-de-antecipacao?ticketId=&q=pedido de antecipação'

export const EMAIL_SUPPORT = 'suporte@refera.com.br'

export const PWA_LINK = 'https://solicitante.refera.com.br'

export const RS_HELP_LINK =
  'https://refera.movidesk.com/kb/pt-br/article/474258/o-que-a-refera-esta-fazendo-para-melhor-atender-a-regiao-afetada-pelas-enchentes-no-rio-grande-do-sul'

export const WARRANTY_POLICY_LINK =
  'https://docs.google.com/document/d/1qQ_DAojZi-QB_clzcRLEtudHMIpoZfwQ/edit?usp=sharing&ouid=117761669289186364614&rtpof=true&sd=true'

export const REFERA_NO_WARRANTY_LINK =
  'https://refera.movidesk.com/kb/pt-br/article/486478/servicos-sem-garantia-da-refera'

export const COVERAGE_WARRANTY_LINK =
  'https://refera.movidesk.com/kb/pt-br/article/495235/entenda-a-diferenca-entre-garantia-do-prestador-e-cobertura-da-refera'

export const REFERA_COVERAGE_MANAGEMENT_LINK =
  'https://refera.movidesk.com/kb/pt-br/article/495230/gestao-de-coberturas-da-refera'

export const REFERA_COVERAGE_LINK =
  'https://refera.movidesk.com/kb/pt-br/article/495231/coberturas-da-refera'

export const HELP_NUMBER = 5548991813893

export const HELP_NUMBER_STRING = '+5548991813893'

export const WHATSAPP_HELP_ICON = `https://wa.me/${HELP_NUMBER}`

export const FINANCE_PAYLOAD_KEYS_TO_UPDATE_BUDGET = [
  'agencyCompletionPercentage',
  'referaCompletionPercentage',
  'withholdingTax',
  'financialIncome',
  'financialIncomeValue',
  'agencyCompletionPercentageValue',
  'referaCompletionPercentageValue',
  'withholdingTaxValue',
  'totalPriceTradesman',
  'totalPriceServiceProvider',
  'totalPrice',
  'liquidValue',
  'referaRegularTax',
  'referaRegularTaxPercentage',
  'warrantyValue',
  'additionalTakeRate',
  'additionalTakeRatePerc',
]

export const ROUTES_TO_HIDE_MENUS = [ROUTES.CONDOLIVRE_CONTRACT]

export const MOVIDESK_LINKS = {
  WITHOUT_REFERA_COVERAGE:
    'https://refera.movidesk.com/kb/pt-br/article/495237/o-que-significa-nao-ter-a-cobertura-da-refera',
  WITH_REFERA_COVERAGE: 'https://refera.movidesk.com/kb/pt-br/article/495231/coberturas-da-refera',
  DIFFERENCE_PROVIDER_GUARANTEE_AND_REFERENCE_COVERAGE:
    'https://refera.movidesk.com/kb/pt-br/article/495235/entenda-a-diferenca-entre-garantia-do-prestador-e-cobertura-da-refera',
}
